import gsap from "gsap"
import { ReactComponent as ArrowSVG } from "images/global/arrow.svg"
import { ReactComponent as DashedMSVG } from "images/global/footerImages/dashed-line-mobile.svg"
import { ReactComponent as DashedSVG } from "images/global/footerImages/dashed-line.svg"
import { ReactComponent as LangSVG } from "images/global/footerImages/lang.svg"
import { ReactComponent as LinkedinSVG } from "images/global/footerImages/linkedin.svg"
import { ReactComponent as LogoSVG } from "images/global/mainLogo.svg"
import { usePreloader } from "library/Loader/PreloaderUtils"
import UniversalLink from "library/Loader/UniversalLink"
import { eases } from "library/eases"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import useMedia from "library/useMedia"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import links from "utils/links"
import { useLangStatus } from "utils/useLangStatus"
import LinkButton from "../Buttons/LinkButton"
import { PrimaryButton } from "../Buttons/Primary"
import Lines from "./Lines"

const footerLinks = [
	{
		name: "Help Center",
		link: links.helpCenter,
		maxDuration: 0.25,
	},
	{
		name: "Security",
		link: links.security,
		maxDuration: 0.2,
	},
	{
		name: "Privacy Policy",
		link: links.privacy,
		maxDuration: 0.3,
	},
	{
		name: "Cookies Policy",
		link: links.cookiePolicy,
		maxDuration: 0.28,
	},
]

export default function Footer() {
	const mobile = useMedia(false, false, false, true)

	usePreloader({
		critical: true,
		duration: 0,
		callback: () => {
			gsap.to("footer", { opacity: 1 })
		},
	})

	const { status, color } = useLangStatus()

	return (
		<Wrapper id="hide-header">
			<Content>
				<Dashed />
				<DashedM />
				<Top>
					<LogoAndCTA>
						<Row>
							<Logo />
							<Subheader>Unstructured Data, Made Usable.</Subheader>
						</Row>

						{!mobile && (
							<PrimaryButton
								to={links.snowflakeMarketplace}
								color="yellow"
								matrixDuration={0.3}
							>
								Get Started
							</PrimaryButton>
						)}
					</LogoAndCTA>
					<Links>
						<Column>
							{footerLinks.map((link, i) => (
								<LinkButton
									key={link.name}
									to={link.link}
									matrixDuration={link.maxDuration}
								>
									{link.name}
								</LinkButton>
							))}
						</Column>
						{mobile && (
							<PrimaryButton
								to={links.snowflakeMarketplace}
								color="yellow"
								matrixDuration={0.3}
							>
								Get Started
							</PrimaryButton>
						)}

						<Column>
							<Status to="https://langai.statuspage.io/">
								<Indicator style={{ background: color }} />
								<Copy>{status}</Copy>
								<StatusArrow />
							</Status>
							<Copy>2024 @ Lang ai All Rights Reserved</Copy>
							<IconWrapper to={links.linkedin} ariaLabel="LinkedIn">
								<Linkedin />
							</IconWrapper>
						</Column>
					</Links>
				</Top>
				<Lang />
				<StyledLines />
			</Content>
		</Wrapper>
	)
}

const Status = styled(UniversalLink)`
	display: flex;
	align-items: center;
`

const Indicator = styled.div`
	${fresponsive(css`
		width: 8px;
		height: 8px;
		border-radius: 99vw;
		margin-right: 8px;
		flex-shrink: 0;
	`)}
`

const StatusArrow = styled(ArrowSVG)`
	${fresponsive(css`
		width: 8px;
		height: 8px;
		rotate: -45deg;
		margin-left: 4px;
		flex-shrink: 0;

		path {
			fill: ${colors.gray400};
		}
	`)}
`

const Wrapper = styled.footer`
	position: relative;
	${colors.backgroundLightBlue};
	display: grid;
	place-items: center;
	opacity: 0;
	overflow: clip;

	${fresponsive(css`
		height: 616px;
	`)}

	${fmobile(css`
		height: 642px;
	`)}
`

const Content = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;

	${fresponsive(css`
		width: 1440px;
		padding: 36px 132px 0;
	`)}

	${ftablet(css`
		width: 1024px;
		padding: 36px 67px 0;
	`)}

	${fmobile(css`
		width: 375px;
		padding: 0 83px;
	`)}
`

const Lang = styled(LangSVG)`
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 1;

	${fresponsive(css`
		width: 1440px;
		height: 317px;
	`)}

	${ftablet(css`
		left: 50%;
		bottom: -7%;
		transform: translateX(-50%);
		width: 105%;
		height: 330px;
	`)}

	${fmobile(css`
		width: 116%;
		bottom: -7.3%;
		height: 190px;
		left: -8%;
	`)}
`

const StyledLines = styled(Lines)`
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 2;
	height: auto;

	${fresponsive(css`
		width: 1227px;
		left: 50%;
		transform: translateX(-50%);
	`)}

	${ftablet(css`
		left: 50%;
		bottom: 2px;
		transform: translateX(-50%);
		width: 928px;
	`)}

	${fmobile(css`
		left: 50%;
		bottom: 0;
		transform: translateX(-50%);
		width: 371px;
	`)}
`

const Top = styled.div`
	display: flex;
	justify-content: space-between;
	${fresponsive(css`
		padding-top: 24px;
	`)}

	${fmobile(css`
		flex-direction: column;
		align-items: center;
		gap: 36px;
	`)}
`
const Dashed = styled(DashedSVG)`
	position: absolute;
	width: 100%;
	height: 2px;
	top: 0;
	left: 0;

	${fmobile(css`
		display: none;
	`)}
`

const Logo = styled(LogoSVG)`
	${fresponsive(css`
		width: 42px;
		height: 48px;
	`)}
`

const LogoAndCTA = styled.div`
	display: flex;
	flex-direction: column;
	${fresponsive(css`
		gap: 24px;
	`)}

	${fmobile(css`
		align-items: center;
		gap: 24px;
	`)}
`

const Row = styled.div`
	display: flex;
	align-items: center;
	${fresponsive(css`
		gap: 12px;
	`)}

	${fmobile(css`
		flex-direction: column;
		gap: 12px;
	`)}
`

const Subheader = styled.h2`
	${textStyles.sub2};
	${fresponsive(css`
		width: 182px;
		text-align: left;
	`)}

	${fmobile(css`
		width: 182px;
		text-align: center;
	`)}
`

const Column = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	${fresponsive(css`
		gap: 24px;
		max-width: 158px;
	`)}

	${fmobile(css`
		align-items: center;
		text-align: center;
	`)}
`

const Links = styled.div`
	display: flex;
	${fresponsive(css`
		gap: 88px;
	`)}

	${fmobile(css`
		flex-direction: column;
		gap: 36px;
		align-items: center;
	`)}
`

const Copy = styled.p`
	color: ${colors.gray500};
	${textStyles.bodyXS};

	${fresponsive(css`
		gap: 24px;
	`)}
`

const Linkedin = styled(LinkedinSVG)`
	position: absolute;
	width: 100%;
	height: 100%;
	left: -1px;

	path {
		transition: fill 0.3s ${eases.expo.inOut};
	}
`

const IconWrapper = styled(UniversalLink)`
	position: relative;

	${fresponsive(css`
		width: 12px;
		height: 12px;
	`)}

	&:hover {
		${Linkedin} {
			path {
				fill: ${colors.darkBlue};
			}
		}
	}
`

const DashedM = styled(DashedMSVG)`
	${fresponsive(css`
		display: none;
	`)}

	${fmobile(css`
		display: inline-block;
		position: absolute;
		width: 345px;
		height: 2px;
		top: 0;
		left: 0;
	`)}
`
