import { ReactComponent as ArrowSVG } from "images/global/arrow.svg"
import UniversalLink, {
	type UniversalLinkProps,
} from "library/Loader/UniversalLink"
import { eases } from "library/eases"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import { useRef, useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import { useMatrixTextAnimation } from "utils/textAnimations"

export default function LinkButton({
	children,
	matrixDuration,
	color = colors.gray500,
	className,
	...props
}: UniversalLinkProps & {
	matrixDuration?: number
	color?: string
	className?: string
}) {
	const [buttonText, setButtonText] = useState<HTMLParagraphElement | null>(
		null,
	)
	const isAnimating = useRef(false)
	const innerRef = useRef<HTMLDivElement>(null)

	const { matrixAnimation } = useMatrixTextAnimation(
		buttonText,
		isAnimating,
		matrixDuration,
	)

	return (
		<Wrapper
			className={className}
			onMouseEnter={matrixAnimation}
			onMouseLeave={matrixAnimation}
			{...props}
		>
			<Inner $color={color} ref={innerRef}>
				<p ref={setButtonText}>{children}</p>
			</Inner>
			<ArrowWrapper $color={color}>
				<ArrowSVG />
			</ArrowWrapper>
		</Wrapper>
	)
}

const ArrowWrapper = styled.div<{ $color: string }>`
	position: relative;
	transition: all 0.2s ${eases.circ.inOut};
	display: grid;
	place-items: center;
	place-content: center;
	${fresponsive(css`
		width: 10px;
		height: 10px;
	`)}

	svg {
		width: 100%;
		height: 100%;

		path {
			fill: ${({ $color }) => $color};
		}
	}
`

const Inner = styled.div<{ $color: string }>`
	width: 100%;
	height: 100%;
	${fresponsive(css`
		display: flex;
		align-items: center;
		justify-content: center;
		max-height: 12px;
		padding-right: 8px;
	`)}

	p {
		z-index: 1;
		color: ${({ $color }) => $color};
		${textStyles.kicker2};
		display: flex;
		justify-content: center;
		white-space: nowrap;
	}

	&::before {
		position: absolute;
		content: "";
		opacity: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		${fresponsive(css`
			background-size: 92px 36px;
		`)}
	}

	${ftablet(css`
		padding: 18px 20px;

		p {
			${textStyles.kicker2};
		}
	`)}

	${fmobile(css`
		p {
			${textStyles.kicker3};
		}
	`)}
`

const Wrapper = styled(UniversalLink)`
	position: relative;
	width: fit-content;
	transition: all 0.3s ${eases.quad.inOut};

	${fresponsive(css`
		height: 12px;
		display: flex;
		align-items: center;
		${textStyles.kicker2};
	`)}

	&:hover {
		&::before {
			opacity: 1;
			scale: 1;
		}

		${ArrowWrapper} {
			transform: rotate(-45deg);
		}
	}
`
