import gsap from "gsap"
import { ReactComponent as LogoSVG } from "images/global/langFull.svg"
import { ReactComponent as MainLogoSVG } from "images/global/mainLogo.svg"
import { usePreloader } from "library/Loader/PreloaderUtils"
import UniversalLink from "library/Loader/UniversalLink"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import useAutoHideHeader from "library/useAutoHideHeader"
import useMedia from "library/useMedia"
import { useRef } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import links from "utils/links"
import LinkButton from "./Buttons/LinkButton"
import { PrimaryButton } from "./Buttons/Primary"

export default function Header() {
	const mobile = useMedia(false, false, false, true)
	const wrapperRef = useRef<HTMLDivElement>(null)
	useAutoHideHeader(wrapperRef)

	usePreloader({
		duration: 0,
		critical: true,
		callback: () => {
			gsap.set(wrapperRef.current, {
				autoAlpha: 1,
			})
			gsap.from(wrapperRef.current, {
				yPercent: -200,
				duration: 4,
				scale: 1.1,
				ease: "power3.inOut",
			})
		},
	})

	return (
		<Wrapper ref={wrapperRef}>
			<Inner>
				<UniversalLink to={links.home} ariaLabel="to home page">
					{mobile ? <MainLogo /> : <Logo />}
				</UniversalLink>
				<Buttons>
					<LinkButton color={colors.teal} to={links.contact}>
						Contact us
					</LinkButton>

					{!mobile && (
						<PrimaryButton color="yellow" to={links.snowflakeMarketplace}>
							Get started
						</PrimaryButton>
					)}
				</Buttons>
			</Inner>
		</Wrapper>
	)
}

const Wrapper = styled.header`
	visibility: hidden;
	position: fixed;
	display: grid;
	place-items: end;
	left: 50%;
	z-index: 5;

	${fresponsive(css`
		left: calc(50vw - 720px + 108px);
		width: 1212px;
		height: 119px;
	`)}

	${ftablet(css`
		width: 924px;
		left: 44px;
	`)}

	${fmobile(css`
		height: 87px;
		width: 351px;
		left: 12px;
	`)}
`

const Logo = styled(LogoSVG)`
	${fresponsive(css`
		width: 150px;
		height: 48px;
		display: block;
	`)}
`

const Inner = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #ffffff50;
	backdrop-filter: blur(10px);
	${fresponsive(css`
		padding: 12px 22px;
		border-radius: 12px;
		width: 1212px;
		height: 71px;
	`)}

	${ftablet(css`
		width: 924px;
	`)}

	${fmobile(css`
		height: 64px;
		width: 351px;
	`)}
`

const Buttons = styled.div`
	display: flex;
	gap: 24px;
	align-items: center;
`

const MainLogo = styled(MainLogoSVG)`
	${fresponsive(css`
		width: 35px;
		height: 40px;
		display: block;
	`)}

	${fmobile(css`
		transform: translateY(3px);
	`)}
`
