import noise from "images/global/noise.webp"
import { fresponsive } from "library/fullyResponsive"
import styled, { keyframes, css } from "styled-components"

const grain = keyframes`
  0%, 100% {background-position: 0 0}
  10% {background-position: -5rem -5rem}
  30% {background-position: 3rem -8}
  50% {background-position: 10rem 10rem}
  70% {background-position: 9rem 3rem}
  90% {background-position: -1rem 7rem}
`

export const Noise = styled.div`
	animation: ${grain} 10s steps(6) infinite;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 9999;
	pointer-events: none;
	left: 0;
	top: 0;
	background-image: url(${noise});
	background-repeat: repeat;

	${fresponsive(css`
		background-size: 420px 420px;
	`)}
`
