import backgroundDarkBlueImg from "images/global/backgrounds/darkBlue.webp"
import backgroundLightBlueImg from "images/global/backgrounds/lightBlue.webp"
import backgroundTealImg from "images/global/backgrounds/teal.webp"
import { createGlobalStyle } from "styled-components"

/**
 * place all your colors here! the format is:
 * [hex color, optional p3 color]
 *
 * if you provide a p3 color, it will be used where supported
 */
const rawColors = {
	pureWhite: ["white"],
	dark: ["black"],
	lightBlue: ["#D4E1E8"],
	lightTeal: ["#749DB3"],
	teal: ["#184861"],
	darkBlue: ["#061E2A"],
	electricYellow: ["#DFFF5F"],
	lightGreen: ["#90BE6D"],
	straightOrange: ["#F5752F"],
	otherBlue: ["#036AA5"],
	techPurple: ["#5B72F2"],
	errorRed: ["#F25B5B"],
	cream: ["#F4F5E5"],
	gray100: ["#F3F4F8"],
	gray200: ["#E1E4EC"],
	gray300: ["#C6C9D1"],
	gray400: ["#A4A7AE"],
	gray500: ["#7C7F87"],
	gray600: ["#5A5C60"],
	gray700: ["#414246"],
	backgroundDarkBlue: [`url(${backgroundDarkBlueImg})`],
	backgroundLightBlue: [`url(${backgroundLightBlueImg})`],
	backgroundLightTeal: [`url(${backgroundDarkBlueImg})`],
	backgroundTeal: [`url(${backgroundTealImg})`],
} as const satisfies Record<string, [string, string] | [string]>

/** widen the type a bit for processing */
const colorEntries: [string, [string, string] | [string]][] =
	Object.entries(rawColors)

/**
 * sets the values of CSS variables globally
 * include this in layout
 */
export const ColorStyle = createGlobalStyle`
	:root {
		@supports (not (color: color(display-p3 0 0 0))) {
			${colorEntries.map(([key, [hex]]) => {
				return `--${key}: ${hex};`
			})}
		}

		@supports (color: color(display-p3 0 0 0)) {
			${colorEntries.map(([key, [hex, p3]]) => {
				return `--${key}: ${p3 ?? hex};`
			})}
		}
	}
`

/**
 * convert the raw colors to an object with the correct color for the current browser
 */
const CSSColors = Object.fromEntries(
	colorEntries.map(([key]) => {
		return [key, `var(--${key})`]
	}),
) as {
	[key in keyof typeof rawColors]: `var(--${key})`
}

/**
 * gsap can't animate variables, so we need to use the hex always
 */
const jsColors = Object.fromEntries(
	colorEntries.map(([key, [color]]) => {
		return [key, color]
	}),
) as {
	[key in keyof typeof rawColors]: (typeof rawColors)[key][0]
}

export default {
	...CSSColors,
	js: jsColors,
}
