import { createStyleString } from "@capsizecss/core"
import fullyResponsive from "library/fullyResponsive"
import { css } from "styled-components"

const saansMetrics = {
	capHeight: 1477,
	ascent: 1864,
	descent: -461,
	lineGap: 0,
	unitsPerEm: 2048,
}

const pPSupplyMetrics = {
	capHeight: 700,
	ascent: 990,
	descent: -210,
	lineGap: 0,
	unitsPerEm: 1000,
}

const makeStyleString = (options: Parameters<typeof createStyleString>[1]) =>
	createStyleString("&", options).replaceAll(".&", "&")

export const transparentText = css`
	/* stylelint-disable-next-line property-no-vendor-prefix  */
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
	background-size: 100%;
	background-clip: text;
`

export const clampText = (lines: number) => css`
	overflow: hidden;
	text-overflow: ellipsis;
	/* stylelint-disable-next-line property-no-vendor-prefix  */
	-webkit-text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: ${lines};
`

const textStyles = {
	clearNestedStyle: css`
		&::before,
		&::after {
			display: none;
		}
	`,
	h1Bold: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 128,
			leading: 128 * 0.95,
			fontMetrics: saansMetrics,
		})};
		font-weight: 570;
		font-style: normal;
		letter-spacing: -5.12px;
	`),
	h1: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 128,
			leading: 128 * 0.95,
			fontMetrics: saansMetrics,
		})};
		font-weight: 380;
		font-style: normal;
		letter-spacing: -5.12px;
	`),
	h2Bold: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 96,
			leading: 96,
			fontMetrics: saansMetrics,
		})};
		font-weight: 570;
		font-style: normal;
		letter-spacing: -3.84px;
	`),
	h2: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 96,
			leading: 96,
			fontMetrics: saansMetrics,
		})};
		font-weight: 380;
		font-style: normal;
		letter-spacing: -3.84px;
	`),
	h3Bold: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 64,
			leading: 64,
			fontMetrics: saansMetrics,
		})};
		font-weight: 570;
		font-style: normal;
		letter-spacing: -1.92px;
	`),
	h3: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 64,
			leading: 64,
			fontMetrics: saansMetrics,
		})};
		font-weight: 380;
		font-style: normal;
		letter-spacing: -1.92px;
	`),
	h4Bold: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 48,
			leading: 48 * 1.05,
			fontMetrics: saansMetrics,
		})};
		font-weight: 570;
		font-style: normal;
		letter-spacing: -1.44px;
	`),
	h4: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 48,
			leading: 48 * 1.05,
			fontMetrics: saansMetrics,
		})};
		font-weight: 380;
		font-style: normal;
		letter-spacing: -1.44px;
	`),
	h5Bold: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 36,
			leading: 36 * 1.05,
			fontMetrics: saansMetrics,
		})};
		font-weight: 570;
		font-style: normal;
		letter-spacing: -0.72px;
	`),
	h5: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 36,
			leading: 36 * 1.05,
			fontMetrics: saansMetrics,
		})};
		font-weight: 380;
		font-style: normal;
		letter-spacing: -0.72px;
	`),
	kicker1: fullyResponsive(css`
		font-family: "PP Supply Sans", sans-serif;
		${makeStyleString({
			fontSize: 18,
			leading: 18 * 1.4,
			fontMetrics: pPSupplyMetrics,
		})};
		font-weight: 400;
		font-style: normal;
		letter-spacing: 2.16px;
		text-transform: uppercase;
	`),
	kicker2: fullyResponsive(css`
		font-family: "PP Supply Sans", sans-serif;
		${makeStyleString({
			fontSize: 16,
			leading: 16 * 1.4,
			fontMetrics: pPSupplyMetrics,
		})};
		font-weight: 400;
		font-style: normal;
		letter-spacing: 1.92px;
		text-transform: uppercase;
	`),
	kicker3: fullyResponsive(css`
		font-family: "PP Supply Sans", sans-serif;
		${makeStyleString({
			fontSize: 14,
			leading: 14 * 1.4,
			fontMetrics: pPSupplyMetrics,
		})};
		font-weight: 400;
		font-style: normal;
		letter-spacing: 1.68px;
		text-transform: uppercase;
	`),
	kicker4: fullyResponsive(css`
		font-family: "PP Supply Sans", sans-serif;
		${makeStyleString({
			fontSize: 9,
			leading: 9 * 1.4,
			fontMetrics: pPSupplyMetrics,
		})};
		font-weight: 400;
		font-style: normal;
		letter-spacing: 0.98px;
		text-transform: uppercase;
	`),
	sub1: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 20,
			leading: 20 * 1.1,
			fontMetrics: saansMetrics,
		})};
		font-weight: 570;
		font-style: normal;
		letter-spacing: -0.2px;
	`),
	sub2: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 18,
			leading: 18 * 1.1,
			fontMetrics: saansMetrics,
		})};
		font-weight: 570;
		font-style: normal;
		letter-spacing: -0.18px;
	`),
	sub3: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 16,
			leading: 16 * 1.1,
			fontMetrics: saansMetrics,
		})};
		font-weight: 570;
		font-style: normal;
		letter-spacing: -0.16px;
	`),
	sub4: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 14,
			leading: 14 * 1.22,
			fontMetrics: saansMetrics,
		})};
		font-weight: 570;
		font-style: normal;
		letter-spacing: -0.14px;
	`),
	bodyXL: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 22,
			leading: 22 * 1.4,
			fontMetrics: saansMetrics,
		})};
		font-weight: 380;
		font-style: normal;
		letter-spacing: 0.22px;
	`),
	bodyL: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 18,
			leading: 18 * 1.4,
			fontMetrics: saansMetrics,
		})};
		font-weight: 380;
		font-style: normal;
		letter-spacing: 0.18px;
	`),
	bodyR: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 16,
			leading: 16 * 1.4,
			fontMetrics: saansMetrics,
		})};
		font-weight: 380;
		font-style: normal;
		letter-spacing: 0.16px;
	`),
	bodyS: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 14,
			leading: 14 * 1.4,
			fontMetrics: saansMetrics,
		})};
		font-weight: 380;
		font-style: normal;
		letter-spacing: 0.14px;
	`),
	bodyXS: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 12,
			leading: 12 * 1.46,
			fontMetrics: saansMetrics,
		})};
		font-weight: 380;
		font-style: normal;
		letter-spacing: 0.12px;
	`),
	productH1Bold: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 22,
			leading: 22 * 0.79545,
			fontMetrics: saansMetrics,
		})};
		font-weight: 570;
		font-style: normal;
	`),
	productH2Bold: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 12.5,
			leading: 12.5 * 1.4,
			fontMetrics: saansMetrics,
		})};
		font-weight: 570;
		font-style: normal;
	`),
	productH2: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 12.5,
			leading: 12.5 * 1.4,
			fontMetrics: saansMetrics,
		})};
		font-weight: 380;
		font-style: normal;
	`),
	productH3Bold: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 9.5,
			leading: 9.5 * 1.5789,
			fontMetrics: saansMetrics,
		})};
		font-weight: 570;
		font-style: normal;
	`),
	productH3: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 9.5,
			leading: 9.5 * 1.5789,
			fontMetrics: saansMetrics,
		})};
		font-weight: 380;
		font-style: normal;
	`),
	productBody: fullyResponsive(css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 8.75,
			leading: 8.75 * 1.2,
			fontMetrics: saansMetrics,
		})};
		font-weight: 380;
		font-style: normal;
	`),
	custom: {
		percents: fullyResponsive(
			css`
				font-family: Saans, sans-serif;
				${makeStyleString({
					fontSize: 100,
					leading: 72,
					fontMetrics: saansMetrics,
				})};
				font-weight: 570;
				font-style: normal;
			`,
			{ scaleFully: true },
		),
	},
}

export default textStyles

export const nonScalingStyles = {
	kicker3: css`
		font-family: "PP Supply Sans", sans-serif;
		${makeStyleString({
			fontSize: 14,
			leading: 14 * 1.4,
			fontMetrics: pPSupplyMetrics,
		})};
		font-weight: 400;
		font-style: normal;
		letter-spacing: 1.68px;
		text-transform: uppercase;
	`,
	h3: css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 64,
			leading: 64,
			fontMetrics: saansMetrics,
		})};
		font-weight: 380;
		font-style: normal;
		letter-spacing: -1.92px;
	`,
	bodyR: css`
		font-family: Saans, sans-serif;
		${makeStyleString({
			fontSize: 16,
			leading: 16 * 1.4,
			fontMetrics: saansMetrics,
		})};
		font-weight: 380;
		font-style: normal;
		letter-spacing: 0.16px;
	`,
}
