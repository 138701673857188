import gsap from "gsap"
import ScrollSmoother from "gsap/ScrollSmoother"
import ScrollTrigger from "gsap/ScrollTrigger"
import SplitText from "gsap/SplitText"
import TextPlugin from "gsap/TextPlugin"
import useAnimation from "../library/useAnimation"

gsap.registerPlugin(TextPlugin, ScrollTrigger, ScrollSmoother, SplitText)

const getRandomLetter = () => {
	return (Math.random() * 26 + 10).toString(36).slice(0, 1)
}

/**
 * @param buttonText - the element containing the text to animate
 * @param isAnimating - a ref to keep track of whether the animation is currently running
 * @param totalDuration - the total duration of the animation
 */
export function useMatrixTextAnimation(
	buttonText: HTMLParagraphElement | HTMLDivElement | HTMLSpanElement | null,
	isAnimating: React.MutableRefObject<boolean>,
	totalDuration = 0.2,
) {
	const timeline = useAnimation(
		() => {
			const tl = gsap.timeline({
				onStart: () => {
					isAnimating.current = true
				},
				onComplete: () => {
					isAnimating.current = false
				},
			})
			const text = new SplitText(buttonText)

			const durationPerChar = totalDuration / text.chars.length // duration for each character
			const delay = durationPerChar * 2

			text.chars.forEach((char, i) => {
				const originalText = char.textContent
				if (!originalText?.match(/[a-z.,':-]/i)) return
				tl.set(
					char,
					{
						text: getRandomLetter(),
						width: () => char.clientWidth,
					},
					0,
				)
				for (let j = 0; j < i; j += 1) {
					tl.set(
						char,
						{
							text: getRandomLetter(),
						},
						delay + durationPerChar * j,
					)
				}
				tl.set(
					char,
					{
						text: originalText,
					},
					delay + durationPerChar * i,
				)
			})

			tl.pause(tl.duration())

			return tl
		},
		[buttonText, isAnimating, totalDuration],
		{ recreateOnResize: true },
	)

	return {
		matrixAnimation: () => {
			timeline?.play(0)
		},
	}
}

export const blurredAnimation = (ref: React.RefObject<HTMLElement>) => {
	if (!ref.current) return

	const tl = gsap.timeline({
		scrollTrigger: {
			trigger: ref.current,
			start: "top 60%",
		},
	})

	const text = new SplitText(ref.current, { type: "words" })

	gsap.set(text.words, {
		y: "+=150%",
		filter: "blur(50px)",
		opacity: 0.25,
		fontFamily: "inherit",
	})

	tl.to(
		text.words,
		{
			y: 0,
			stagger: 0.05,
			duration: 0.5,

			ease: "power2.inOut",
		},
		0,
	).to(
		text.words,
		{
			filter: "blur(0px)",
			stagger: 0.05,
			duration: 0.5,
			opacity: 1,
			ease: "power2.inOut",
		},
		0.1,
	)
}

export function useBlurredHeaderAnimation(
	ref: React.RefObject<HTMLElement>,
	canAnimate = true,
) {
	useAnimation(() => {
		if (!ref.current) return
		const text = new SplitText(ref.current, { type: "words" })

		gsap.set(ref.current, { opacity: 1 })
		gsap.set(text.words, {
			y: "+=150%",
			filter: "blur(50px)",
			opacity: 0,
			fontFamily: "inherit",
		})

		if (!canAnimate) return

		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: ref.current,
				start: "top 80%",
			},
		})

		tl.to(
			text.words,
			{
				y: 0,
				stagger: 0.05,
				duration: 0.5,

				ease: "power2.inOut",
			},
			0,
		).to(
			text.words,
			{
				filter: "blur(0px)",
				stagger: 0.05,
				duration: 0.5,
				opacity: 1,
				ease: "power2.inOut",
			},
			0.1,
		)
	}, [ref, canAnimate])
}
