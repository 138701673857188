import { useEffect, useState } from "react"
import colors from "styles/colors"
import z from "zod"

const endpoint = "https://langai.statuspage.io/api/v2/status.json"

const schema = z.object({
	status: z.object({
		indicator: z.enum(["none", "minor", "major", "critical"]),
		description: z.string(),
	}),
})

export const useLangStatus = () => {
	const [status, setStatus] = useState<string>("Checking status...")
	const [color, setColor] = useState<string>(colors.gray500)

	useEffect(() => {
		const fetchStatus = async () => {
			const response = await fetch(endpoint)
			const json = await response.json()

			const { data } = schema.safeParse(json)
			if (data) {
				setStatus(data.status.description)
				switch (data.status.indicator) {
					case "none":
						setColor(colors.lightGreen)
						break
					case "minor":
						setColor(colors.electricYellow)
						break
					case "major":
						setColor(colors.straightOrange)
						break
					case "critical":
						setColor(colors.errorRed)
						break
				}
			} else {
				setStatus("Unknown Status")
			}
		}

		fetchStatus()
	}, [])

	return { status, color }
}
