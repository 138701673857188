import gsap from "gsap"
import CustomEase from "gsap/CustomEase"
import useAnimation from "library/useAnimation"
import { useEffect, useRef } from "react"
import styled from "styled-components"

gsap.registerPlugin(CustomEase)

const RADIUS = 6
const POINTS = {
	group1: [
		[28.3398, 218.507],
		[63, 219],
		[118, 218.507],
		[204, 162],
		[261, 162],
		[288, 161],
		[372, 107],
	],
	group2: [
		[120, 107],
		[155, 107],
		[201, 107],
		[265, 129],
		[371, 164],
		[423.014, 198.029],
		[454, 218],
		[503, 218],
		[579.395, 218.507],
		[659.447, 218.507],
		[735.777, 218.507],
		[797, 219],
		[838, 243],
		[879, 267],
		[920, 267],
		[961, 267],
	],
	group3: [
		[882, 119],
		[964, 118],
		[1049, 73],
		[1049, 51],
		[1133, 51],
		[1216, 51],
	],
	group4: [
		[625, 163],
		[659, 163],
		[732, 163],
		[811, 163],
		[892, 163],
		[969, 163],
		[1047, 163],
	],
	group5: [
		[971, 10],
		[1047, 10],
		[1131, 10],
	],
	group6: [
		[28.3398, 275.219],
		[111, 275],
		[189, 275],
		[265, 275],
		[283, 275],
	],
	group7: [
		[460, 278],
		[504, 278],
		[576, 278],
		[625, 278],
		[664, 264],
		[712, 248],
		[794, 247],
		[811, 242],
		[884, 220],
		[966, 220],
		[1050, 220],
		[1132, 220],
		[1215, 165],
	],
	group8: [
		[33, 50],
		[116, 50],
		[189, 74],
		[286, 105],
		[344, 85],
		[373, 74],
		[458, 28],
		[481, 49],
		[508, 75],
		[539, 104],
		[578, 118],
		[624, 132],
		[660, 122],
		[711, 106],
		[745, 84],
		[792, 52],
	],
	group9: [
		[285, 218],
		[348, 200],
		[419, 177],
		[457, 164],
		[495, 164],
		[534, 163],
		[578, 137],
		[625, 108],
		[659, 86],
		[714, 51],
		[757, 51],
		[820, 51],
		[882, 51],
		[963, 78],
		[1048, 106],
		[1128, 106],
		[1218, 106],
	],
}

export default function FooterLines({
	className = "",
}: {
	className?: string
}) {
	const wrapperRef = useRef<SVGSVGElement | null>(null)
	const activeIndex = useRef<number | null>(null)
	const activeId = useRef<keyof typeof POINTS | null>(null)

	useEffect(() => {
		const footer = document.getElementById("hide-header")

		const resetPosition = () => {
			if (
				!wrapperRef.current ||
				activeIndex.current === null ||
				!activeId.current
			)
				return

			const startingVals = POINTS[activeId.current][activeIndex.current]
			if (!startingVals || !startingVals[0] || !startingVals[1]) return

			gsap.to(`.${activeId.current}-circle-${activeIndex.current}`, {
				attr: {
					cx: startingVals[0],
					cy: startingVals[1],
				},
				ease: "elastic",
				duration: 1,
			})
			gsap.to(`.${activeId.current}-line-${activeIndex.current}`, {
				attr: {
					x1: startingVals[0],
					y1: startingVals[1],
				},
				ease: "elastic",
				duration: 1,
			})
			gsap.to(`.${activeId.current}-line-${activeIndex.current - 1}`, {
				attr: {
					x2: startingVals[0],
					y2: startingVals[1],
				},
				ease: "elastic",
				duration: 1,
			})

			activeId.current = null
			activeIndex.current = null
		}

		const ratioize = (value: number, viewbox: number, wrapper: number) => {
			const ratio = viewbox / wrapper
			return value * ratio
		}

		const mouseMove = (e: MouseEvent) => {
			if (
				!wrapperRef.current ||
				activeIndex.current === null ||
				!activeId.current ||
				!footer
			)
				return

			if (
				e.clientX < footer.getBoundingClientRect().x ||
				e.clientY < footer.getBoundingClientRect().y
			) {
				resetPosition()
			} else {
				const wrapperRect = wrapperRef.current.getBoundingClientRect()
				const y = ratioize(e.clientY - wrapperRect.y, 1227, wrapperRect.width)
				const x = ratioize(e.clientX - wrapperRect.x, 308, wrapperRect.height)

				gsap.set(`.${activeId.current}-circle-${activeIndex.current}`, {
					attr: {
						cx: x,
						cy: y,
					},
				})
				gsap.set(`.${activeId.current}-line-${activeIndex.current}`, {
					attr: {
						x1: x,
						y1: y,
					},
				})
				gsap.set(`.${activeId.current}-line-${activeIndex.current - 1}`, {
					attr: {
						x2: x,
						y2: y,
					},
				})
			}
		}

		window.addEventListener("mousemove", mouseMove)
		window.addEventListener("mouseup", resetPosition)

		return () => {
			window.removeEventListener("mousemove", mouseMove)
			window.removeEventListener("mouseup", resetPosition)
		}
	}, [])

	const renderLine = (id: keyof typeof POINTS, color: string) => {
		return POINTS[id].map((item, index, arr) => {
			let line = null
			if (index >= 0 && index < arr.length - 1) {
				line = (
					<Line
						className={`${id}-line-${index}`}
						x1={item[0]}
						y1={item[1]}
						x2={arr[index + 1]?.[0]}
						y2={arr[index + 1]?.[1]}
						stroke={color}
						strokeWidth={RADIUS}
					/>
				)
			}

			const mouseDown = (index: number) => {
				activeIndex.current = index
				activeId.current = id
			}

			return (
				<g key={item.toString()}>
					<Circle
						className={`${id}-circle-${index}`}
						cx={item[0]}
						cy={item[1]}
						r={RADIUS}
						fill={color}
						onMouseDown={(e) => {
							e.preventDefault()
							mouseDown(index)
						}}
					/>
					{line}
				</g>
			)
		})
	}

	useAnimation(
		() => {
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: wrapperRef.current,
					start: "clamp(top bottom)",
				},
				defaults: {
					ease: "linear",
				},
			})

			const mult = 0.1

			Object.keys(POINTS).forEach((id, lineIndex) => {
				POINTS[id as keyof typeof POINTS].forEach((_, index) => {
					tl.from(
						`.${id}-circle-${index}`,
						{
							attr: {
								r: 0,
							},
							duration: mult,
						},
						lineIndex * mult + index * mult,
					)

					tl.from(
						`.${id}-line-${index}`,
						{
							attr: {
								x2: (_, target) => target.getAttribute("x1"),
								y2: (_, target) => target.getAttribute("y1"),
							},
							duration: mult,
						},
						lineIndex * mult + index * mult + mult,
					)
				})
			})
		},
		[],
		{
			scope: wrapperRef,
		},
	)

	return (
		<svg
			ref={wrapperRef}
			className={className}
			width="1227"
			height="308"
			overflow="visible"
			viewBox="0 0 1227 308"
			fill="none"
		>
			<title>Click and drag a node</title>
			{renderLine("group1", "#184861")}
			{renderLine("group2", "#5A5C60")}
			{renderLine("group3", "#184861")}
			{renderLine("group4", "#F5752F")}
			{renderLine("group5", "#90BE6D")}
			{renderLine("group6", "#DFFF5F")}
			{renderLine("group7", "#DFFF5F")}
			{renderLine("group8", "#90BE6D")}
			{renderLine("group9", "#036AA5")}
		</svg>
	)
}

const Line = styled.line`
	pointer-events: none;
`

const Circle = styled.circle`
	cursor: grab;
`
