import { ReactComponent as ArrowSVG } from "images/global/arrow.svg"
import UniversalLink, {
	type UniversalLinkProps,
} from "library/Loader/UniversalLink"
import { eases } from "library/eases"
import { fresponsive } from "library/fullyResponsive"
import { gradientBorderBefore } from "library/generateGradientBorder"
import { forwardRef, useRef, useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import { useMatrixTextAnimation } from "utils/textAnimations"

const variants = {
	yellow: {
		background: colors.electricYellow,
		"--border":
			"linear-gradient(192deg, #F4FFC9 10.53%, rgba(152, 191, 0, 0.25) 103.4%)",
	},
	gray: {
		background:
			"linear-gradient(112deg, rgba(255, 255, 255, 0.00) -23.25%, rgba(255, 255, 255, 0.50) 142.11%)",
		"--border":
			"linear-gradient(174deg, rgba(255, 255, 255, 0.75) -35.8%, rgba(245, 245, 245, 0.25) 128.86%)",
	},
}

type ColorType = keyof typeof variants
type PrimaryButtonProps = UniversalLinkProps & {
	color: ColorType
	matrixDuration?: number
}

function PrimaryButtonRaw({
	children,
	color,
	matrixDuration,
	...props
}: PrimaryButtonProps) {
	const [textEl, setTextEl] = useState<HTMLParagraphElement | null>(null)
	const { matrixAnimation } = useMatrixTextAnimation(textEl, useRef(false))

	return (
		<Wrapper
			style={variants[color]}
			onMouseEnter={matrixAnimation}
			onMouseLeave={matrixAnimation}
			{...props}
		>
			<Text ref={setTextEl}>{children}</Text>
			<Arrow />
			<Highlight />
		</Wrapper>
	)
}

export const PrimaryButton = forwardRef((props: PrimaryButtonProps, ref) => (
	// @ts-expect-error types of UniversalLink are wrong and it's not worth fixing before react 19 is out
	<PrimaryButtonRaw {...props} forwardRef={ref} />
))

const Arrow = styled(ArrowSVG)`
	${fresponsive(css`
		width: 10px;
		height: 10px;
		transition: rotate 0.3s ${eases.quad.inOut};
	`)}
`

const Text = styled.p`
	${textStyles.kicker2};
	color: ${colors.darkBlue};
`

const Highlight = styled.div`
	position: absolute;
	background: linear-gradient(189deg, transparent 36.03%, #fff 128.05%);
	border-radius: 50%;
	top: -45%;
	height: 100%;
	width: 250%;
	left: 50%;
	translate: -50%;
	z-index: -1;
	opacity: 0.15;
	transition: opacity 0.3s ${eases.quad.inOut};
`

const Wrapper = styled(UniversalLink)`
	${fresponsive(css`
		width: fit-content;
		position: relative;
		display: grid;
		grid-template-columns: 1fr auto;
		place-items: center;
		padding: 18px 23.5px;
		border-radius: 16px;
		isolation: isolate;
		overflow: clip;
		gap: 8px;
	`)}

	${gradientBorderBefore("var(--border)", 1.5)}

	&:hover {
		${Arrow} {
			rotate: -45deg;
		}

		${Highlight} {
			opacity: 0.75;
		}
	}
`
