import { type Config, defaultConfig } from "library/defaultConfig"

/**
 * The transition names that can be used in the page transition
 */
export type TransitionNames = never

const config: Config = {
	...defaultConfig,
	defaultTransition: "instant",
	getTimeNeeded: (startTime: number) => startTime * 2 + 2000,
}

export default config
