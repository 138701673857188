import gsap from "gsap"
import { loader } from "library/Loader"
import { eases } from "library/eases"
import { fresponsive } from "library/fullyResponsive"
import { useRef, useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

export default function Preloader() {
	const wrapper = useRef<HTMLDivElement>(null)
	const [progress, setProgress] = useState(0)

	loader.useEventListener("progressUpdated", setProgress)
	loader.useEventListener("end", () => {
		gsap.to(wrapper.current, { autoAlpha: 0 })
	})

	return (
		<div ref={wrapper}>
			<Wrapper>
				<Inner style={{ width: `${progress}%` }} />
			</Wrapper>
			<Text>{Math.round(progress)}%</Text>
		</div>
	)
}

const Wrapper = styled.div`
	${fresponsive(css`
		width: 172px;
		padding: 2px;
		position: fixed;
		top: calc(50% + 77px);
		left: 50%;
		translate: -50%;
		z-index: 99;
		background: ${colors.lightTeal};
		border-radius: 99vw;
	`)}
`
const Inner = styled.div`
	${fresponsive(css`
		padding: 3px;
		background: ${colors.electricYellow};
		border-radius: 99vw;
		transition: width 0.2s ${eases.quad.out};
	`)}
`

const Text = styled.div`
	${textStyles.kicker3};
	${fresponsive(css`
		position: fixed;
		top: calc(50% + 100px);
		left: 50%;
		translate: -50%;
		z-index: 99;
		color: ${colors.lightTeal};
	`)}
`
